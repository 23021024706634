<template>
    <div class="c-peeking-carousel-module">
        <Carousel
            :name="name"
            :carouselItems="slides"
            :desktopPeekPercent="20"
            :desktopSlideSize="3"
            :mediumSlideSize="3"
            :mobileSlideSize="1"
            :onlyShowPaginationOnHover="true"
            :explicitSlideGap="gutters"
            :mobilePeek="true"
            :mobilePeekPercent="20"
            :noContainerMargin="true"
            :hasIncreasedPageButtonSpacing="true"
            :lightButtons="lightButtons"
            :resetOnResize="true"
            slideClass="c-peeking-carousel-module__slide"
        >
            <template
                v-for="(slide, index) in slides"
                #[`carouselItem-${index}`]
            >
                <template v-if="isMounted && slide.moduleType === CONTENTFUL_MODULE_TYPES.SHOPPABLE_IMAGE">
                    <component
                        :is="shoppableImageModule"
                        :key="slide.title"
                        :backgroundImage="slide.backgroundImage"
                        :buttons="slide.buttons"
                        :editorialText="slide.editorialText"
                        :choiceIds="slide.choices"
                        :ctaPosition="slide.ctaPosition"
                    />
                </template>
                <template v-else-if="isMounted">
                    <ImageModule
                        :key="slide.title"
                        :backgroundImage="slide.backgroundImage"
                        :buttons="slide.buttons"
                        :destination="slide.destination"
                        :editorialText="slide.editorialText"
                        :isFullWidth="true"
                        :numberOfModules="1"
                    />
                </template>
            </template>
        </Carousel>
    </div>
</template>

<script setup>
import { computed } from 'vue';

import { CONTENTFUL_MODULE_TYPES } from '~coreModules/contentful/js/contentful-constants';
import carouselModuleProps from '~coreModules/contentful/js/carouselModuleProps.js';

import { useMounted } from '~coreModules/core/js/composables/useMounted';

import ImageModule from '~coreModules/contentful/components/ImageModule.vue';
import Carousel from '~coreModules/core/components/ui/Carousel.vue';

const CAROUSEL_MODULE_GUTTERS_DIVISOR = 8;
// this ensures that the contentful module gutters align with our 8px grid system
const CAROUSE_MODULE_GUTTERS = [0.5, 1, 1.5, 2];
const CAROUSEL_MODULE_GUTTERS_MAP = new Map(CAROUSE_MODULE_GUTTERS.map(gutter => [gutter, gutter * 8]));

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable vue/valid-define-props */
const props = defineProps(carouselModuleProps.props);
const isMounted = useMounted();

function getCarouselGutters(gutters) {
    const key = gutters / CAROUSEL_MODULE_GUTTERS_DIVISOR;
    return CAROUSEL_MODULE_GUTTERS_MAP.get(key) || [...CAROUSEL_MODULE_GUTTERS_MAP.values()].find(Boolean);
}
const gutters = computed(() => getCarouselGutters(props.gutters));
</script>

<style lang="scss">
</style>
