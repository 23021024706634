<template>
    <LazyLoad :bottomMargin="scrollOffset">
        <template #default="{ hasEnteredViewport }">
            <div
                :class="{
                    'c-content-scroll-fade-in': true,
                    'c-content-scroll-fade-in--hide': !hasEnteredViewport,
                }"
            >
                <slot :hasEnteredViewport="hasEnteredViewport"></slot>
            </div>
        </template>
    </LazyLoad>
</template>

<script>

import LazyLoad from '~coreModules/core/components/ui/LazyLoad.vue';

export default {
    name: 'ContentScrollFadeIn',
    components: {
        LazyLoad,
    },
    props: {
        scrollOffset: {
            type: String,
            default: '-128px',
        },
    },
};
</script>

<style lang="scss">
    .c-content-scroll-fade-in {
        opacity: 1;
        transition: opacity 1s ease-out;

        &--hide {
            opacity: 0;
        }
    }
</style>
