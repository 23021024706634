<template>
    <div
        v-tab-focus="emitClick"
        :class="{
            'c-pagination-button': true,
            'c-pagination-button--light': isLight,
            'c-pagination-button--translucent': isTranslucent,
            'c-pagination-button--disabled': isDisabled,
            'c-pagination-button--disabled-hidden': isDisabled && hideWhenDisabled,
        }"
        :aria-label="label"
        :aria-disabled="isDisabled"
    >
        <BaseIcon
            icon="global--chevron"
            size="16px"
            :color="arrowColor"
            :orientation="direction === 'forward' ? 'right' : 'left'"
        />
    </div>
</template>

<script>

export default {
    name: 'PaginationButton',
    props: {
        direction: {
            type: String,
            required: true,
            validator(dir) {
                return ['forward', 'backward'].indexOf(dir) !== -1;
            },
        },
        label: {
            type: String,
            required: true,
        },
        isLight: {
            type: Boolean,
            default: false,
        },
        isTranslucent: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        hideWhenDisabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click'],
    computed: {
        arrowColor() {
            if (this.isTranslucent && this.isDisabled) {
                return 'gray';
            }

            if (this.isDisabled) {
                return 'medium-gray';
            }

            return 'primary';
        },
    },
    methods: {
        emitClick(e) {
            if (!this.isDisabled) {
                this.$emit('click', e);
            }
        },
    },
};
</script>

<style lang="scss">
    .c-pagination-button {
        $this: &;

        height: 40px;
        width: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.2s ease;
        border-radius: 2px;
        background-color: $nu-secondary;
        cursor: pointer;

        &--light {
            background-color: $nu-white;
        }

        &--disabled {
            cursor: default;
        }

        &--disabled-hidden {
            opacity: 0;
            pointer-events: none;
        }

        &--translucent {
            background-color: rgba($nu-white, 0.8);
        }
    }
</style>
