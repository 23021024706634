<i18n>
    [
        "global__previous",
        "global__next",
    ]
</i18n>
<template>
    <div v-if="carouselItems.length" class="c-carousel">
        <div
            v-tab-focus="handleLinkClick"
            :class="{
                'c-carousel__container--no-margin': noContainerMargin,
                'c-carousel__container--link': wrappingLink,
                ...fadientClasses,
            }"
            class="c-carousel__container"
            @keyup.left="scrollToPrev"
            @keyup.right="scrollToNext"
        >
            <ul
                :id="scrollContainerId"
                ref="scrollContainer"
                :class="{
                    'c-carousel__slide-container': true,
                    'c-carousel__slide-container--peek-shift': enableCarouselShiftOnPaginate,
                    'o-flex-horizontal-center': shouldCenterContent,
                }"
                tabindex="-1"
            >
                <li
                    v-for="(carouselItem, index) in carouselItems"
                    :ref="setSlideRef"
                    :key="`carousel-${name}-${index}`"
                    :class="{
                        [slideClass]: !!slideClass,
                        'c-carousel__slide': true,
                        'c-carousel__slide--fixed-width': !useVariableWidth,
                        'c-carousel__slide--snap-start': isSwiping,
                        'c-carousel__slide--snap-stop': scrollSnapStop,
                    }"
                >
                    <!--
                            by default, the slot name will be carouselItem-${index}
                            carouselItemUniqueKey is used when the number of items in a carousel can change dynamically
                            i.e. closeted products tray, an item can be removed
                        -->
                    <slot :name="getCarouselSlotName(carouselItem,carouselItemUniqueKey,index)"></slot>
                </li>
            </ul>
        </div>
        <PaginationButton
            v-if="!shouldHideButtons"
            :direction="'backward'"
            :label="`${$t('global__next')} - ${name}`"
            :isDisabled="disableBackButton"
            :hideWhenDisabled="enableCarouselShiftOnPaginate"
            :class="{
                'c-carousel__button': true,
                'c-carousel__button--left': !hasIncreasedPageButtonSpacing,
                'c-carousel__button--left-peeking': enableCarouselShiftOnPaginate,
                'c-carousel__button--left-far': hasIncreasedPageButtonSpacing,
                'c-carousel__button--hover-only': onlyShowPaginationOnHover,
                'c-carousel__button--disabled-hide': disableBackButton && enableCarouselShiftOnPaginate,
            }"
            :isTranslucent="isTranslucent"
            :isLight="lightButtons"
            @click="changePage(DIRECTION_PREVIOUS)"
            @keyup.left="scrollToPrev"
            @keyup.right="scrollToNext"
        />
        <PaginationButton
            v-if="!shouldHideButtons"
            :direction="'forward'"
            :label="`${$t('global__previous')} - ${name}`"
            :isDisabled="disableNextButton"
            :hideWhenDisabled="enableCarouselShiftOnPaginate"
            :class="{
                'c-carousel__button': true,
                'c-carousel__button--right': !hasIncreasedPageButtonSpacing,
                'c-carousel__button--right-peeking': enableCarouselShiftOnPaginate,
                'c-carousel__button--right-far': hasIncreasedPageButtonSpacing,
                'c-carousel__button--hover-only': onlyShowPaginationOnHover,
                'c-carousel__button--disabled-hide': disableNextButton && enableCarouselShiftOnPaginate,
            }"
            :isTranslucent="isTranslucent"
            :isLight="lightButtons"
            @click="changePage(DIRECTION_NEXT)"
            @keyup.left="scrollToPrev"
            @keyup.right="scrollToNext"
        />
        <div v-if="shouldShowCarouselPagination" class="c-carousel__slide-marker-container">
            <span
                v-for="(_, index) in pageCount"
                :key="`carousel-${name}-marker-${index}`"
                class="c-carousel__slide-marker"
                @click="changePage(null, index * pageSize)"
            >
                <span
                    class="c-carousel__slide-marker-dot"
                    :class="{ 'c-carousel__slide-marker-dot--current': pageIndex === index }"
                ></span>
            </span>
        </div>
    </div>
</template>

<script>
import { uniqueId } from 'lodash-es';
import { mapActions, mapState } from 'vuex';

import {
    DIRECTION_NEXT,
    DIRECTION_PREVIOUS,
    NEXT,
    PREVIOUS,
} from '~coreModules/core/js/constants';
import { CAROUSEL_SCROLL } from '~coreModules/core/js/global-event-constants';

import { getCarouselSlotName } from '~coreModules/core/js/carousel-utils';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { BROWSER_MODULE_NAME } from '~coreModules/browser/js/browser-store';

import PaginationButton from '~coreModules/core/components/ui/buttons/PaginationButton.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Carousel',
    components: {
        PaginationButton,
    },
    props: {
        useVariableWidth: {
            type: Boolean,
            default: false,
        },
        analyticsType: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        carouselItems: {
            type: Array,
            required: true,
        },
        noContainerMargin: {
            type: Boolean,
            default: false,
        },
        noSlidePadding: {
            type: Boolean,
            default: false,
        },
        onlyShowPaginationOnHover: {
            type: Boolean,
            default: false,
        },
        defaultSlide: {
            type: Number,
            default: 0,
        },
        translucentButtons: {
            type: Boolean,
            default: false,
        },
        lightButtons: {
            type: Boolean,
            default: false,
        },
        hideButtons: {
            type: Boolean,
            default: false,
        },
        hasIncreasedPageButtonSpacing: {
            type: Boolean,
            default: false,
        },
        slideClass: {
            type: String,
            default: '',
        },
        catalogResponseId: {
            type: String,
            default: null,
        },
        resetOnResize: {
            type: Boolean,
            default: false,
        },
        wrappingLink: {
            type: [String, Object],
            default: '',
        },
        // utilize this if the carousel items are not static
        // i.e. closeted products tray - an item can be removed
        carouselItemUniqueKey: {
            type: String,
            default: null,
        },
        hasLargeSlidePadding: {
            type: Boolean,
            default: false,
        },
        carouselPageIndex: {
            type: Number,
            default: null,
        },
        onScrollComplete: {
            type: Function,
            default: () => {},
        },
        displaySliderFadient: {
            type: Boolean,
            default: false,
        },
        explicitSlideGap: {
            type: Number,
            default: null,
        },
        shouldCenterContentForSinglePage: {
            type: Boolean,
            default: false,
        },
        // FIXED WIDTH ITEM LOGIC ONLY
        desktopSlideSize: {
            type: Number,
            default: 5,
        },
        xlDesktopSlideSize: {
            type: Number,
            default: null,
        },
        mediumSlideSize: {
            type: Number,
            default: 3,
        },
        mobileSlideSize: {
            type: Number,
            default: 2,
        },
        mobilePeek: {
            type: Boolean,
            default: true,
        },
        mobilePeekPercent: {
            type: Number,
            default: 15,
        },
        mediumPeekPercent: {
            type: Number,
            default: 15,
        },
        desktopPeekPercent: {
            type: Number,
            default: 0,
        },
        displayCarouselPagination: {
            type: Boolean,
            default: false,
        },
        selectedCuration: {
            type: String,
            default: '',
        },
    },
    emits: ['carousel-moved', 'click'],
    data() {
        return {
            isMounted: false,
            DIRECTION_NEXT,
            DIRECTION_PREVIOUS,
            isScrolling: false,
            isSwiping: false,
            lastScrollDirection: DIRECTION_NEXT,
            numberOfItemsInView: 1,
            viewableItemsMap: {},
            scrollAnimationOptions: {
                container: undefined,
                easing: 'ease-in-out',
                force: true,
                x: true,
                y: false,
            },
            scrollDebounce: 100,
            scrollLeft: 0,
            scrollContainer: null,
            scrollContainerId: undefined,
            scrollDuration: 400,
            scrollTimer: null,
            isUsingPaginationButtons: false,
            slideRefs: [],
            getCarouselSlotName,
            unwatchPageIndex: null,
            intersectionObserver: null,
            intersectionObserversAreBound: false,
            isFirstSlideInView: false,
            isLastSlideInView: false,
        };
    },
    computed: {
        ...mapState(BROWSER_MODULE_NAME, [
            'isResizing',
        ]),

        // BEGIN FIXED WIDTH ONLY
        pageSize() {
            if (this.useVariableWidth) return null;

            if (this.$mediaQueries.isSmallish) {
                return this.mobileSlideSize;
            }

            if (this.$mediaQueries.isMedium) {
                return this.mediumSlideSize;
            }

            if (this.$mediaQueries.isLarge) {
                return this.desktopSlideSize;
            }

            return this.xlDesktopSlideSize || this.desktopSlideSize;
        },
        pageCount() {
            if (this.useVariableWidth) return null;

            return Math.ceil(this.carouselItems.length / this.pageSize);
        },
        pageIndex() {
            if (this.useVariableWidth) return null;

            if (this.numberOfItemsInView === this.carouselItems.length) {
                return this.pageCount - 1;
            }
            return Math.max(0, Math.floor(this.numberOfItemsInView / this.pageSize) - 1);
        },
        carouselItemWidthPercentage() {
            if (this.useVariableWidth) return null;

            const gapOffsetPerSlide = ((this.pageSize - 1) * this.slideGap) / this.pageSize;

            if (this.shouldCarouselPeek && this.pageCount > 1) {
                const { mobilePeekPercent, mediumPeekPercent, desktopPeekPercent, $mediaQueries } = this;
                // eslint-disable-next-line no-nested-ternary
                const peekPercentage = $mediaQueries.isLargish ? desktopPeekPercent :
                    ($mediaQueries.isMedium ? mediumPeekPercent : mobilePeekPercent);

                return `calc(${100 / (this.pageSize + (peekPercentage * 0.01))}% - ${gapOffsetPerSlide}px)`;
            }

            return `calc(${100 / this.pageSize}% - ${gapOffsetPerSlide}px)`;
        },
        shouldShowCarouselPagination() {
            return this.displayCarouselPagination && this.isMounted && !this.isOnlyOnePage && !this.useVariableWidth;
        },

        // END FIXED WIDTH ONLY

        isTranslucent() {
            return this.translucentButtons || (this.isMounted && !this.$mediaQueries.isLargish);
        },
        isOnlyOnePage() {
            if (!this.scrollContainer || this.numberOfItemsInView <= 0) return true;

            if (this.useVariableWidth) {
                return this.intersectionObserversAreBound ? (this.isFirstSlideInView && this.isLastSlideInView) : true;
            }

            return this.pageCount <= 1;
        },
        disableBackButton() {
            if (!this.scrollContainer) return true;

            if (this.useVariableWidth) return this.scrollLeft === 0;

            return this.numberOfItemsInView === this.pageSize;
        },
        disableNextButton() {
            if (!this.scrollContainer || this.numberOfItemsInView <= 0) return true;

            if (this.useVariableWidth) {
                const { scrollContainer: { offsetWidth, scrollWidth } } = this;
                return this.scrollLeft + offsetWidth >= scrollWidth;
            }

            return this.numberOfItemsInView === this.carouselItems.length;
        },
        shouldMobilePeek() {
            return !this.$mediaQueries.isLargish && this.mobilePeek &&
                    this.mobilePeekPercent > 0;
        },
        shouldDesktopPeek() {
            return this.$mediaQueries.isLargish && this.desktopPeekPercent > 0;
        },
        shouldMediumPeek() {
            return this.$mediaQueries.isMedium && this.mediumPeekPercent > 0;
        },
        shouldCarouselPeek() {
            return this.shouldMobilePeek || this.shouldDesktopPeek || this.shouldMediumPeek;
        },
        shouldHideButtons() {
            return this.hideButtons || this.isOnlyOnePage;
        },
        scrollSnapStop() {
            return 'normal';
        },
        slideGap() {
            if (this.explicitSlideGap) {
                return this.explicitSlideGap;
            }

            if (this.noSlidePadding) {
                return 0;
            }

            if (this.hasLargeSlidePadding) {
                return 16;
            }

            return 8;
        },
        slideGapPx() {
            return `${this.slideGap}px`;
        },
        shouldCenterContent() {
            return this.shouldCenterContentForSinglePage && this.isOnlyOnePage;
        },
        fadientClasses() {
            if (!this.displaySliderFadient || this.isOnlyOnePage) return [];

            return {
                'c-carousel__container--fadient-left': this.disableNextButton,
                'c-carousel__container--fadient-right': this.disableBackButton,
                'c-carousel__container--fadient-both': !this.disableBackButton && !this.disableNextButton,
            };
        },
        enableCarouselShiftOnPaginate() {
            return (
                this.useVariableWidth || (!this.noSlidePadding &&
                (this.desktopPeekPercent || !this.$mediaQueries.isLargish) &&
                (this.onlyShowPaginationOnHover || !this.$mediaQueries.isLargish) &&
                (this.noContainerMargin || !this.$mediaQueries.isLargish))
            );
        },
        scrollPeekOffset() {
            if (!this.enableCarouselShiftOnPaginate) return 0;
            if (this.$mediaQueries.isSmallish) return -16;

            return this.$mediaQueries.isMedium ? -32 : -64;
        },
    },
    watch: {
        isResizing(isResizing, wasResizing) {
            if (wasResizing && !isResizing && this.resetOnResize) {
                this.resetCarousel();
                this.setNumberOfItemsInView();
            }
        },
        isScrolling(isScrolling, wasScrolling) {
            const { scrollContainer: { scrollLeft } } = this;

            if (isScrolling && !this.isUsingPaginationButtons) {
                this.isSwiping = true;

                if (scrollLeft > this.scrollLeft) {
                    this.lastScrollDirection = DIRECTION_NEXT;
                } else {
                    this.lastScrollDirection = DIRECTION_PREVIOUS;
                }

                this.scrollLeft = scrollLeft;
            }

            if (!isScrolling && wasScrolling) {
                this.scrollLeft = scrollLeft;
                this.isUsingPaginationButtons = false;
                this.setNumberOfItemsInView();
            }
        },
        'slideRefs.length': {
            handler(newValue, oldValue) {
                if (newValue > oldValue) {
                    this.bindIntersectionObservers();
                }
            },
            deep: true,
        },
        /* navigate backwards if the slide being viewed has products removed, and current slide becomes empty */
        'carouselItems.length': {
            async handler() {
                await this.$nextTick();

                if (this.scrollContainer) {
                    const { scrollContainer: { scrollLeft } } = this;
                    const firstViewableItemIndex = this.slideRefs
                        .findIndex(item => item?.offsetLeft >= scrollLeft) || 0;

                    if (firstViewableItemIndex > -1) {
                        this.changePage(DIRECTION_PREVIOUS);
                    }
                }
            },
        },
        noSlidePadding() {
            this.$nextTick(() => {
                this.resetCarousel();
            });
        },
        numberOfItemsInView(newCount) {
            this.$emit('carousel-moved', {
                numberOfItemsInView: newCount,
            });

            if (newCount > 0) {
                this.trackGlobalEvent({
                    type: CAROUSEL_SCROLL,
                    data: {
                        label: this.lastScrollDirection === DIRECTION_NEXT ? NEXT : PREVIOUS,
                        value: newCount,
                        contentModuleId: this.name,
                        catalogResponseId: this.catalogResponseId,
                        analyticsType: this.analyticsType,
                        curation: this.selectedCuration,
                    },
                });
            }

        },
        carouselPageIndex(newIndex, oldIndex) {
            if (typeof newIndex === 'number' && newIndex !== oldIndex) {
                this.changePage(null, newIndex);
            }
        },
    },
    created() {
        if (this.onScrollComplete) {
            this.scrollAnimationOptions.onDone = this.onScrollComplete;
        }
    },
    async mounted() {
        this.isMounted = true;
        const uuid = uniqueId();
        this.scrollContainerId = `scroll-container-${uuid}`;
        this.scrollAnimationOptions.container = `#${this.scrollContainerId}`;

        await this.$nextTick();

        if (this.$refs.scrollContainer) {
            this.scrollContainer = this.$refs.scrollContainer;
            this.scrollContainer.addEventListener('scroll', this.scrollHandler);

            await this.$nextTick();

            if (this.defaultSlide > 0) {
                this.setScrollPosition(null, this.defaultSlide);
            }

            if (this.$mediaQueries.isSmallish) {
                this.isSwiping = true;
            }

            this.bindIntersectionObservers();
            await this.$nextTick();
            this.setNumberOfItemsInView();
        }
    },
    beforeUpdate() {
        this.slideRefs = [];
    },
    unmounted() {
        if (this.scrollContainer) {
            this.scrollContainer.removeEventListener('scroll', this.scrollHandler);
        }
        this.unwatchPageIndex?.();

        this.intersectionObserver?.disconnect?.();
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        resetCarousel() {
            if (this.scrollContainer) {
                this.setScrollPosition(null, 0, false);
            }
        },
        bindIntersectionObservers() {
            if (!('IntersectionObserver' in window)) return;
            this.intersectionObserver?.disconnect?.();
            this.intersectionObserversAreBound = false;
            this.intersectionObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        const el = entry.target;
                        const index = el.dataset.slideIndex;
                        const isFullyVisible = entry.isIntersecting && entry.intersectionRatio >= 0.95;
                        this.viewableItemsMap[index] = isFullyVisible;

                        if (el === this.slideRefs?.[0]) {
                            this.isFirstSlideInView = isFullyVisible;
                        }
                        if (el === this.slideRefs?.[this.slideRefs?.length - 1]) {
                            this.isLastSlideInView = isFullyVisible;
                        }
                    });

                    if (!this.isScrolling) {
                        this.setNumberOfItemsInView();
                    }
                },
                {
                    root: this.$refs.scrollContainer,
                    threshold: [0, 0.5, 0.95],
                },
            );

            if (this.slideRefs?.length) {
                this.slideRefs.forEach((slideEl, i) => {
                    if (!slideEl) return;
                    // eslint-disable-next-line no-param-reassign
                    slideEl.dataset.slideIndex = i;
                    this.viewableItemsMap[i] = false;
                    this.intersectionObserver.observe(slideEl);
                });
                this.intersectionObserversAreBound = true;
            }
        },
        setNumberOfItemsInView() {
            this.numberOfItemsInView = Math.max(
                ...Object.keys(this.viewableItemsMap)
                    .map(Number)
                    .filter(key => this.viewableItemsMap[key] === true),
                0,
            ) + 1;
        },
        getScrollToElementIndex(direction, explicitItemIndex) {
            // Allow up to 2px difference to accommodate fractional offsets
            const ALLOWED_DIFFERENCE = 2;

            let scrollToElementIndex = explicitItemIndex;
            const isValueNaN = value => Number.isNaN(parseInt(value, 10));

            if (isValueNaN(explicitItemIndex)) {
                const { scrollContainer: { scrollLeft, offsetWidth } } = this;

                if (direction === DIRECTION_PREVIOUS) {
                    const gapOffset = this.slideGap || 0;

                    // Find the index of the first fully viewable slide in view
                    const firstViewableItemIndex = this.slideRefs.findIndex(
                        item => (item?.offsetLeft || 0) >= (scrollLeft - ALLOWED_DIFFERENCE),
                    ) || 0;

                    const firstViewableOffsetLeft = this.slideRefs?.[firstViewableItemIndex]?.offsetLeft || 0;
                    const previousScrollLeft = firstViewableOffsetLeft - offsetWidth - gapOffset;

                    // Find the index of the slide which is fully visible in the previous scroll view
                    scrollToElementIndex = this.slideRefs.findIndex(
                        item => (item?.offsetLeft || 0) >= (previousScrollLeft - ALLOWED_DIFFERENCE),
                    ) || 0;

                } else {
                    const rightEdge = scrollLeft + offsetWidth;

                    // Find the index of the first fully viewable item in the next scroll view
                    this.slideRefs.some((item, index) => {
                        const itemWidth = item?.offsetWidth || 0;
                        const itemOffsetLeft = item?.offsetLeft || 0;
                        const itemOffsetRight = itemOffsetLeft + itemWidth;

                        scrollToElementIndex = index;
                        if (itemOffsetRight <= (rightEdge + ALLOWED_DIFFERENCE)) {
                            return false;
                        }
                        return true;
                    });
                }
            }

            return scrollToElementIndex;
        },
        setScrollPosition(direction, explicitItemIndex, shouldAnimate = true) {
            if (this.scrollContainer) {
                const scrollToElementIndex = this.getScrollToElementIndex(direction, explicitItemIndex);
                const { scrollContainer: { scrollLeft } } = this;

                if (!(scrollToElementIndex === 0 && scrollLeft === 0)) {
                    if (shouldAnimate) {
                        this.$scrollTo(
                            this.slideRefs[scrollToElementIndex],
                            this.scrollDuration,
                            {
                                ...this.scrollAnimationOptions,
                                offset: this.scrollPeekOffset,
                            },
                        );
                    } else {
                        const scrollToElement = this.slideRefs?.[scrollToElementIndex];

                        if (scrollToElement) {
                            this.scrollContainer.scrollLeft = scrollToElement.offsetLeft;
                        } else {
                            // eslint-disable-next-line max-len
                            this.$logger.debugError('no element was found at the desired index, preventing carousel navigation');
                        }
                    }
                }
            }
        },
        scrollHandler() {
            window.requestAnimationFrame(() => {
                clearTimeout(this.scrollTimer);

                if (!this.isScrolling) {
                    this.isScrolling = true;
                }

                this.scrollTimer = setTimeout(() => {
                    this.isScrolling = false;
                }, this.scrollDebounce);
            });
        },
        changePage(direction, explicitItemIndex) {
            if (!this.isScrolling) {
                this.isUsingPaginationButtons = true;
                this.isSwiping = false;
                this.lastScrollDirection = direction;
                this.setScrollPosition(direction, explicitItemIndex);
            }
        },
        scrollToPrev(e) {
            e?.stopImmediatePropagation();
            this.changePage(DIRECTION_PREVIOUS);
        },
        scrollToNext(e) {
            e?.stopImmediatePropagation();
            this.changePage(DIRECTION_NEXT);
        },
        setSlideRef(el) {
            if (el) {
                this.slideRefs.push(el);
            }
        },
        handleLinkClick(e) {
            this.$emit('click', e);
            if (this.wrappingLink) {
                this.$router.push(this.wrappingLink);
            }
        },
    },
};
</script>

<style lang="scss">
    // Defining custom property so transition can be applied
    @property --fade-left {
        syntax: "<number>";
        inherits: false;
        initial-value: 1;
    }
    @property --fade-right {
        syntax: "<number>";
        inherits: false;
        initial-value: 1;
    }

    .c-carousel {
        $this: &;
        position: relative;

        &:focus-within,
        &:hover {
            #{$this}__button {
                &:not(&--disabled-hide) {
                    opacity: 0.8;
                }
            }
        }

        &__container {
            white-space: nowrap;
            display: block;
            outline: none;
            background: inherit;

            @include non-touch-device {
                --fade-left: 1;
                --fade-right: 1;
                mask-size: 100%;
                -webkit-mask-size: 100%;
                mask-repeat: no-repeat;
                -webkit-mask-repeat: no-repeat;
                transition:
                    --fade-left 0.2s ease,
                    --fade-right 0.2s ease;

                $gradient: linear-gradient(
                    to right,
                    rgba(0, 0, 0, var(--fade-left)) 0%,
                    rgba(0, 0, 0, 1) 5%,
                    rgba(0, 0, 0, 1) 95%,
                    rgba(0, 0, 0, var(--fade-right)) 100%
                );
                mask-image: $gradient;
                -webkit-mask-image: $gradient;
            }

            &--fadient-left {
                @include non-touch-device {
                    #{$this}:hover &,
                    #{$this}:focus-within & {
                        --fade-left: 0.2;
                        --fade-right: 1;
                    }
                }
            }

            &--fadient-right {
                @include non-touch-device {
                    #{$this}:hover &,
                    #{$this}:focus-within & {
                        --fade-left: 1;
                        --fade-right: 0.2;
                    }
                }
            }

            &--fadient-both {
                @include non-touch-device {
                    #{$this}:hover &,
                    #{$this}:focus-within & {
                        --fade-left: 0.2;
                        --fade-right: 0.2;
                    }
                }
            }

            &.focus-visible:focus[data-focus-visible-added] {
                outline: none;
            }

            &:not(&--no-margin) {
                @include breakpoint(large) {
                    margin: 0 $nu-spacer-12;
                }
            }

            &--link {
                cursor: pointer;
            }
        }

        &__slide-container {
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
            transform: matrix(1, 0, 0, 1, 0, 0);
            scroll-snap-type: x mandatory;
            width: 100%;
            line-height: 0;
            transition-duration: 400ms;
            transition-timing-function: ease;
            padding: 5px 0;
            margin: -5px 0;
            scrollbar-width: none;
            gap: v-bind(slideGapPx);

            &::-webkit-scrollbar {
                display: none;
            }

            &--peek-shift {
                padding-inline-start: $nu-spacer-2;
                padding-inline-end: $nu-spacer-2;
                scroll-padding: 0 $nu-spacer-2;

                @include breakpoint(medium) {
                    padding-inline-start: $nu-spacer-4;
                    padding-inline-end: $nu-spacer-4;
                    scroll-padding: 0 $nu-spacer-4;
                }

                @include breakpoint(large) {
                    padding-inline-start: $nu-spacer-8;
                    padding-inline-end: $nu-spacer-8;
                    scroll-padding: 0 $nu-spacer-8;
                }
            }
        }

        &__slide {
            display: inline-block;
            line-height: normal;
            flex: 0 0 auto;

            &--snap-start {
                scroll-snap-align: start;
            }

            &--snap-start:last-child {
                scroll-snap-align: end;
            }

            scroll-snap-stop: v-bind(scrollSnapStop);

            &--fixed-width {
                width: v-bind(carouselItemWidthPercentage);
            }
        }

        &__button {
            position: absolute;
            top: 0;
            bottom:0;
            margin: auto;

            @include touch-device {
                display: none;

                @include breakpoint(large) {
                    display: flex;
                }
            }

            @include non-touch-device {
                transition: opacity 0.2s ease;
                opacity: 0;

                @include breakpoint(large) {
                    opacity: 1;

                    &--hover-only {
                        opacity: 0;
                    }
                }

            }

            &--left {
                left: 0;

                @include non-touch-device {
                    margin-left: $nu-spacer-2;
                }
            }

            &--left-peeking {
                @include breakpoint(medium) {
                    margin-left: $nu-spacer-4;
                }

                @include breakpoint(large) {
                    margin-left: $nu-spacer-8;
                }
            }

            &--left-far {
                left: $nu-spacer-4;
            }

            &--right {
                right: 0;

                @include non-touch-device {
                    margin-right: $nu-spacer-2;
                }
            }

            &--right-peeking {
                @include breakpoint(medium) {
                    margin-right: $nu-spacer-4;
                }

                @include breakpoint(large) {
                    margin-right: $nu-spacer-8;
                }
            }

            &--right-far {
                right: $nu-spacer-4;
            }
        }

        &__slide-marker-container {
            align-items: center;
            margin-top: $nu-spacer-3;
            display: flex;
            justify-content: center;
            width: 100%;
        }

        &__slide-marker {
            padding: $nu-spacer-0pt5;
            margin-right: $nu-spacer-0pt5;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }
        }

        &__slide-marker-dot {
            display: block;
            border-radius: 100%;
            border: 1px solid $nu-primary;
            height: 8px;
            width: 8px;
            transition: background-color 0.2s ease;

            &--current {
                background-color: $nu-primary;
            }
        }
    }
</style>
